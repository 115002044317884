<template>
	<please-select-utility alert-class="ma-3">
		<data-loader
				:watch="utility + '_' + session"
				:callback="getCurrentSession"
				:data.sync="currentSession" @ready="setSession()">
			<template #loading>
				<v-progress-linear indeterminate rounded/>
			</template>
			<template #default>
				<v-alert
					v-if="currentSession === 0"
					colored-border border="left" elevation="3"
					type="error" class="ma-3"
				> Няма активна сесия за текущото дружество!
				</v-alert>
				<component :imodel="model" v-else-if="session && currentSession" :is="component" v-bind="$route.params" :progress="true"/>
			</template>
		</data-loader>
	</please-select-utility>
</template>

<script>
import DailySchedule from "@/views/otcheti/otchetnik_ui/DailySchedule.vue";
import StationView from "@/views/otcheti/otchetnik_ui/StationView.vue";
import ImotView from "@/views/otcheti/otchetnik_ui/ImotView.vue";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "@/views/otcheti/otchetnik_ui/Model";

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {
		DataLoader,
		PleaseSelectUtility,
		DailySchedule,
		StationView,
		ImotView,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
		ThrowMeMixin,
	],
	props: {
		utility: {},
		session: {},
		date: {},
		station: {},
		imot: {},
	},
	data() {
		return {
			currentSession: null,
			model,
		}
	},
	computed: {
		component(){
			if(this.$route.params.imot){
				return 'ImotView';
			}
			if(this.$route.params.station){
				return 'StationView';
			}
			return 'DailySchedule';
		},
	},
	watch: {
		utility(utility){
			const newRooterParams = {
				utility,
				session: undefined,
				date: undefined,
				station: undefined,
				imot: undefined,
			};
			let hasChanges = false;
			for(const paramName in newRooterParams){
				if(newRooterParams[paramName] !== this.$router.history.current.params[paramName]){
					hasChanges = true;
					break;
				}
			}
			if(!hasChanges){
				return;
			}
			this.$router.replace({
				name: this.$route.name,
				params: newRooterParams,
			});
		},
	},
	methods: {
		getCurrentSession() {
			return this.session || !this.utility
				? new Promise((resolve) => resolve(this.session))
				: model.getCurrentSession(this.utility)
					.catch(res => this.throwMe(res));
		},
		setSession(){
			if(this.session || !this.currentSession){
				return;
			}
			this.$router.replace({
				name: this.$route.name,
				params: {
					utility: this.utility,
					session: this.currentSession,
					date: undefined,
					station: undefined,
					imot: undefined,
				},
			});
		},
	},
}
</script>